import React from 'react'
import Header from '../homepage/header'
import Section2 from '../homepage/section2'
import Section3 from '../homepage/section3'
// import Section4 from '../homepage/section4'
import Section5 from '../homepage/section5'
import Section6 from '../homepage/section6'

export default function index() {
  return (
    <div>
        <Header/>
        <Section2/>
        <Section3/>
        <Section5/>
        <Section6/>
    </div>
  )
}
