import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../src/images/vvlogo.webp";
import Brand from "../../images/ban.png";

export default function header() {
  return (
    <div>
      {/* <section
        class="relative sm:px-24 sm:pb-24 lg:pb-32 overflow-hidden bg-white"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="relative container px-4 mx-auto z-20">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full lg:w-2/5 sm:mt-10 px-4 mb-8 lg:mb-0">
                <div class="relative max-w-lg lg:max-w-md mx-auto lg:mx-0">
                  <img
                    class="block h-94 md:h-2/5 w-full rounded-lg"
                    src={Brand}
                    alt=""
                  />
                  <div class="absolute bottom-0 left-0 p-7"></div>
                </div>
              </div>
              <div class="w-full lg:w-3/5 px-4">
                <div class="max-w-lg lg:max-w-2xl mx-auto lg:mr-0">
                  <div class="max-w-2xl">
                    <h1 class="font-heading text-gray-800 font-semibold mb-8 sm:mb-10">
                      <span class="text-3xl sm:text-4xl">
                        Welcome to <br />
                      </span>
                      <span class="text-3xl sm:text-5xl text-yellow-900">
                        Unipole Hoardings by Sri Raam Infra Ventures
                      </span>
                    </h1>
                  </div>
                  <div class="md:flex mb-10 max-w-xs sm:max-w-sm md:max-w-none">
                    <div class="max-w-lg">
                      <p class="text-lg md:text-xl text-gray-900 font-semibold">
                        Unipole Hoardings by Sri Raam Infra Ventures is your
                        trusted partner in the manufacturing and installation of
                        premium outdoor advertising structures across India. As
                        suppliers to advertising providers, we specialize in
                        delivering high-quality, eye-catching advertising
                        solutions that make a significant impact on your
                        campaigns.
                      </p>
                    </div>
                  </div>
                  <div class="sm:flex items-center">
                    <a
                      class="relative group inline-block w-full sm:w-auto py-4 px-6 text-white font-semibold bg-yellow-600 rounded-md overflow-hidden"
                      href="/contact"
                    >
                      <div class="absolute top-0 right-full w-full h-full bg-yellow-600 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <div class="relative flex items-center justify-center ">
                        <button>
                          {" "}
                          <Link exact to="/contact">
                            {" "}
                            <span class="mr-4">Meet The Expert</span>
                          </Link>
                        </button>
                        <span>
                          <svg
                            width="8"
                            height="12"
                            viewbox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.83 5.29L2.59 1.05C2.49704 0.956274 2.38644 0.881879 2.26458 0.83111C2.14272 0.780342 2.01202 0.754204 1.88 0.754204C1.74799 0.754204 1.61729 0.780342 1.49543 0.83111C1.37357 0.881879 1.26297 0.956274 1.17 1.05C0.983753 1.23736 0.879211 1.49082 0.879211 1.755C0.879211 2.01919 0.983753 2.27264 1.17 2.46L4.71 6L1.17 9.54C0.983753 9.72736 0.879211 9.98082 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.71C6.92373 6.61704 6.99813 6.50644 7.04889 6.38458C7.09966 6.26272 7.1258 6.13201 7.1258 6C7.1258 5.86799 7.09966 5.73728 7.04889 5.61543C6.99813 5.49357 6.92373 5.38297 6.83 5.29Z"
                              fill="#FFF2EE"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
          <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav class="relative flex flex-col py-6 px-10 w-full h-full bg-white border-r overflow-y-auto">
            <div class="flex items-center mb-16">
              <a class="mr-auto text-2xl font-medium leading-none" href="#">
                <img class="h-10" src={Logo} alt="" width="auto" />
              </a>
              <button class="navbar-close">
                <svg
                  class="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </nav>
        </div>
      </section> */}

      <section class="md:relative overflow-hidden md:pt-28 md:mx-20">
        <div class="">
          <div class="absolute bottom-10 left-0 w-full h-full flex items-end ">
            <img class="hidden lg:block w-full object-top" src={Brand} alt="" />
          </div>
          <div class="relative container px-4 mx-auto ">
            <div class=" md:mb-16 lg:mb-32">
              <p className="text-xl md:text-2xl md:w-1/2 mb-2">Welcome to</p>
              <h1 class="font-heading text-2xl xs:text-6xl md:text-4xl  font-semibold text-gray-900 mb-4 md:mb-8 md:w-1/2">
                <span class="text-yellow-900 w-1/2">Unipole Hoardings by</span>
                <br></br>
                <span class="text-yellow-900 w-1/2">
                  Sri Raam Infra Ventures
                </span>
              </h1>
              <div>
                <p class="text-lg mb-5 md:w-1/2">
                  Unipole Hoardings by Sri Raam Infra Ventures is your trusted
                  partner in the
                  <br className="hidden md::block"/> manufacturing and installation of premium outdoor
                  advertising structures
                  <br className="hidden md:block"/> across India. As suppliers to advertising providers, we
                  specialize in delivering
                  <br className="hidden md:block"/>
                  high-quality, eye-catching advertising solutions that make a
                  significant impact
                  <br className="hidden md:block"/> on your campaigns.
                </p>
              </div>
              <div class="lg:flex items-center w-1/2">
                <div class="mb-8 lg:mb-0 lg:mr-8">
                  <a
                    class="relative group inline-block w-full sm:w-auto md:py-4 py-3 md:px-6 px-3 text-white font-semibold bg-yellow-700 rounded-md overflow-hidden"
                    href="/contact"
                  >
                    <div class="relative flex items-center justify-center">
                      <span class="mr-4">Meet The Expert</span>
                      <span>
                        <svg
                          width="8"
                          height="12"
                          viewbox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.83 5.29L2.59 1.05C2.49704 0.956274 2.38644 0.881879 2.26458 0.83111C2.14272 0.780342 2.01202 0.754204 1.88 0.754204C1.74799 0.754204 1.61729 0.780342 1.49543 0.83111C1.37357 0.881879 1.26297 0.956274 1.17 1.05C0.983753 1.23736 0.879211 1.49082 0.879211 1.755C0.879211 2.01919 0.983753 2.27264 1.17 2.46L4.71 6L1.17 9.54C0.983753 9.72736 0.879211 9.98082 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.71C6.92373 6.61704 6.99813 6.50644 7.04889 6.38458C7.09966 6.26272 7.1258 6.13201 7.1258 6C7.1258 5.86799 7.09966 5.73728 7.04889 5.61543C6.99813 5.49357 6.92373 5.38297 6.83 5.29Z"
                            fill="#FFF2EE"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// ..............................................new code .....................................................................................
