import React from "react";
import Image from "../../images/sale.webp";
import Image1 from "../../images/uni2.png";
import Image2 from "../../images/uni1.png";
import Image3 from "../../images/uni3.png";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "swiper/swiper-bundle.css";

import { Autoplay } from "swiper";

export default function section6() {
  return (
    <div>
      <section
        class="relative py-5 md:py-16 overflow-hidden bg-yellow-100"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="w-full mx-auto">
            <div class="flex flex-wrap -mx-4 mb-18 items-center">
              <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div class="max-w-md xl:max-w-2xl ml-7">
                  <h1 class="font-heading text-2xl md:text-4xl text-gray-800">
                    <span>Our</span>
                    <span className="italic text-yellow-900 font-semibold"> Happy</span>
                    <span className="italic text-yellow-900 font-semibold">
                      {" "}
                      Clients     </span><br /> says about us
                
                  </h1>
                </div>
              </div>
            </div>
            <div class="lg:flex md:mt-20 md:ml-80">
              <Swiper
                pagination={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  "@1.00": {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  "@1.50": {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <div>
                  <SwiperSlide>
                    <div className="md:flex">
                      <div class="w-full mb-8 lg:mb-0">
                        <div class="">
                          <img
                            class=" rounded-lg md:w-96"
                            src={Image2}
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="mx-4">
                        <p class="text-xl font-semibold text-gray-900 mb-5">
                          Very professional work. Super satisfied with the work
                          they did. Layout board and Road sign board will be my
                          choice if a new requirement pops up again. On time
                          delivery and excellent service at affordable price.
                        </p>
                        <p class=" text-gray-900 font-semibold mb-1">
                          &mdash; Nagesh
                        </p>
                        <p class=" text-gray-800">Vastava Infra</p>
                        <p class=" text-gray-800 mb-10">Mysore</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="md:flex">
                      <div class="w-full mb-8 lg:mb-0">
                        <div class="">
                          <img
                            class=" rounded-lg md:w-96"
                            src={Image3}
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="mx-4">
                        <p class="text-xl font-semibold text-gray-900 mb-5">
                          Good service with good mentioning . Super satisfied
                          with the work they did. Layouts board and road sign
                          boad will be my choice if a new requirement pops up
                          again. On time delivery and excellent service at
                          affordable price.
                        </p>
                        <p class=" text-gray-900 font-semibold mb-1">
                          &mdash; Anand Rachai
                        </p>
                        <p class=" text-gray-800">First reality square feet</p>
                        <p class=" text-gray-800 mb-10">Bangalore</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="md:flex">
                      <div class="w-full mb-8 lg:mb-0">
                        <div class="">
                          <img
                            class=" rounded-lg md:w-96"
                            src={Image1}
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="mx-4">
                        <p class="text-xl font-semibold text-gray-900 mb-5">
                          We got Billboard Media’s number from a billboard
                          location. From the initial inquiry to puting our
                          poster up was a smooth process. We definitely
                          recommend them to other businesses.
                        </p>
                        <p class=" text-gray-900 font-semibold mb-1">
                          &mdash; Dhatri projects
                        </p>
                        <p class=" text-gray-800 mb-10">Mysore</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
