import React from "react";
import Image from "../../images/3dhording.jpg";
import Image1 from "../../images/shiv2.jpg";
import Image3 from "../../images/digital.jpg";

export default function section3() {
  return (
    <div>
      <section
        class="relative md:py-16 overflow-hidden bg-white"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 mb-10 items-center">
              <div class="w-full lg:w-2/3 xl:w-1/2 px-4 mb-6 sm:mb-12 lg:mb-0">
                <div>
                  <p class="inline-block py-1 px-3 sm:mb-4 text-md font-semibold text-white bg-yellow-600 rounded-full mt-2">
                    Services
                  </p>
                  <h1 class="font-heading text-2xl md:text-4xl text-yellow-900 font-semibold mt-2">
                    <p>Crafting <span className="">Hoardings</span> with Precision and Care</p>
                  </h1>
                </div>
              </div>
              <div class="w-full lg:w-1/3 xl:w-1/2 px-4 ">
                <div class="max-w-sm lg:ml-auto">
                  <p class="text-lg text-gray-900 font-semibold">
                    We are a team of 20+ who are passionate about building
                    better & safe Hoardings.
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-4">
              <div class="w-full xl:w-1/2 mb-8 xl:mb-0">
                <div class="relative h-100 md:h-125">
                  <img
                    class="block h-full w-full rounded-3xl object-cover opacity-80"
                    src={Image}
                    alt=""
                  />
                  <div class="absolute top-0 left-0 h-full w-full p-4 md:p-8">
                    <div class="flex flex-col items-start h-full max-w-sm">
                      <div>
                        <span class="lg:mb-3 text-sm md:text-xl text-black font-bold  uppercase bg-gray-100 rounded-md p-1">
                          3D BILLBOARD HOARDINGS
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full xl:w-1/2 px-4">
                <div class="flex flex-wrap h-full -mx-4">
                  <div class="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                    <div class="flex flex-col h-full">
                      <a class="pb-2" href="/services">
                          <div class="w-full xl:w-1/2 mb-8 xl:mb-0">
                          <div class="relative h-52 w-72 md:h-125">
                            <img
                              class="block h-full w-full rounded-3xl object-cover opacity-80"
                              src={Image1}
                              alt=""
                            />
                            <div class="absolute top-0 left-0 h-full w-full p-4 md:p-8">
                              <div class="flex flex-col items-start h-full max-w-sm">
                                <div>
                                  <span class="lg:mb-3 text-sm md:text-xl text-black font-bold uppercase bg-gray-100 rounded-md p-1">
                                    UNIPOLE HOARDING
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <a class="" href="/services">
                        <div class="relative h-52 w-72 md:h-125">
                          <img
                            class="block h-full w-full rounded-3xl object-cover opacity-80"
                            src={Image3}
                            alt=""
                          />
                          <div class="absolute top-0 left-0 h-full w-full p-4 md:p-8">
                            <div class="flex flex-col items-start h-full max-w-sm">
                              <div>
                                <span class="lg:mb-3 text-sm md:text-xl text-black font-bold uppercase bg-gray-100 rounded-md p-1">
                                  DIGITAL BILLBOARD HOARDINGS
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 px-4">
                    <a
                      class="relative block h-80 pt-8 px-4 pb-5 rounded-3xl bg-orange-50 hover:bg-orange-100 transition duration-200"
                      href="#"
                    >
                      <div class="flex flex-col items-start justify-between max-w-lg">
                        <span class="text-xl font-semibold text-black">
                          At Unipole Hoardings, we offer premium outdoor
                          advertising solutions across India. Choose from our
                          iconic Unipole Hoardings, dynamic Digital Billboard
                          Hoardings, and eye-catching 3D Billboard Hoardings to
                          make your brand stand out.
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
