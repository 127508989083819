import React from 'react'
import Section1 from './section1'
import Section2 from './section2'

export default function index() {
  return (
    <div>
        <Section2/>
        <Section1/>
    </div>
  )
}
