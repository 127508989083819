import React from "react";
import Image from "../../images/shiv7.jpg";

export default function section1() {
  return (
    <div>
      <section
        class="relative py-5 md:py-16 lg:py-20 overflow-hidden bg-white"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-5xl mx-auto">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-14 lg:mb-0">
                <div class="max-w-md lg:max-w-lg mx-auto lg:mx-0">
                  <h1 class="font-heading text-2xl md:text-4xl text-yellow-900 mb-6 font-semibold">
                    <span>About Us</span>
                  </h1>
                  <div class="max-w-lg mb-10">
                    <p class="text-gray-800 font-semibold text-justify mx-2">
                      At Unipole Hoardings, we take pride in our expertise in
                      manufacturing a wide range of advertising structures that
                      enhance your brand's visibility. From Unipole Hoardings to
                      Digital Billboard Hoardings, 3D Billboard Hoardings, and
                      Highway Billboard Hoardings, we provide top-tier products
                      designed to meet your advertising needs.
                    </p>
                  </div>
                  <div class="inline-block sm:inline-flex items-center pl-5 py-3 pr-6 bg-yellow-600 rounded-full shadow-xl">
                    <div>
                      <a href="/contact" class="text-white">
                        Get Consultation Today
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2">
                <div class="relative max-w-md lg:max-w-sm mx-auto lg:mr-0">
                  <img class="md:w-full rounded-lg md:h-72" src={Image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
