import React from "react";
import { Link } from "react-router-dom";
import ImageOne from "../../images/shiv3.jpg";
import ImageTwo from "../../images/shiv4.jpg";
import ImageThree from "../../images/shiv12.jpg";
import ImageFour from "../../images/shiv5.jpg";
import ImageFive from "../../images/shiv8.jpg";
import ImageSix from "../../images/shiv9.jpg";

export default function section1() {
  return (
    <div>
      <section>
        <div
          class="md:py-20 sm:px-20  bg-white radius-for-skewed"
          style={{ fontFamily: "Figtree" }}
        >
          <div class="container md:px-4 mx-auto">
            <div class="md:mb-16 justify-center md:justify-between items-center">
              <div class="text-center lg:text-left">
                <h2 class="text-2xl lg:text-4xl font-heading text-gray-800 mb-5 justify-center text-center">
                  Featured <span className="italic text-yellow-900">Projects</span>
                </h2>
              </div>
            </div>

            <div class="flex flex-wrap mb-4">
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageOne} alt="" />
              </div>
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageTwo} alt="" />
              </div>
              <div class="p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageThree}
                  alt=""
                />
              </div>
              <div class=" p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageFour}
                  alt=""
                />
              </div>
              <div class="p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageFive}
                  alt=""
                />
              </div>
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageSix} alt="" />
              </div>
            </div>
            

          </div>
        </div>
      </section>
    </div>
  );
}
