import React from "react";
import Icon1 from "../../images/icon1.png";
import Icon2 from "../../images/icon3.png";
import Icon3 from "../../images/icon5.png";
import Icon4 from "../../images/icon2.png";
import Icon5 from "../../images/icon4.png";
import Icon6 from "../../images/icon6.png";
import Build from "../../images/shiv13.jpg";

export default function section2() {
  return (
    <div>
      <section
        class="relative overflow-hidden bg-yellow-100"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="relative container px-2 sm:px-4 sm:mx-auto mt-10 sm:mt-24">
          <div class="sm:max-w-7xl  mx-auto">
            <div class="flex flex-wrap items-center -mx-4 mb-24">
              <div class="w-full lg:w-1/2 sm:mr-24 px-8 sm:px-4 mb-16 lg:mb-0">
                <div class="max-w-lg lg:max-w-xl mx-auto lg:mx-0">
                  <h1 class="font-heading text-3xl md:text-4xl font-semibold text-yellow-900 mb-4 md:mb-8">
                    <p>Building Site <span class=''>Hoarding</span></p>
                  </h1>
                  <p class="max-w-xl text-lg md:text-lg font-medium text-gray-800">
                    Your nationwide partner for premium advertising structures.
                    From Bangalore to Mumbai, Delhi to Chennai, and everywhere
                    in between, our iconic Unipole Hoardings, dynamic Digital
                    Billboard Hoardings, and captivating 3D Billboard Hoardings
                    can be found strategically positioned across India. We
                    manufacture excellence in outdoor advertising.
                  </p>
                </div>
              </div>
              <div class=" w-full lg:w-1/3 sm:px-2  ">
                <img
                  class="rounded-lg h-[400px] w-[350px] md:h-[500px] md:w-[400px] mx-5"
                  src={Build}
                  alt="Holding"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-wrap -mx-4 sm:mb-8">
            <div class="w-full lg:w-1/3 px-4 mb-4 sm:mb-12 lg:mb-0 lg:border-r border-gray-100">
              <div class="max-w-xs mx-auto pb-10 lg:pb-5 text-center border-b border-gray-100 lg:border-b-0">
                <div class="flex mx-auto mb-5 items-center justify-center w-14 h-14 rounded-full ">
                  <img src={Icon1} alt="" />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                Our Manufacturing<br/> Excellence
                </h4>
                <p class="text-black font-semibold">
                  At Unipole Hoardings by Sri Raam Infra Ventures, we take pride
                  in our in-house printing facility. With state-of-the-art
                  equipment and a dedicated team of experts.
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-4 sm:mb-12 lg:mb-0 lg:border-r border-gray-100">
              <div class="max-w-xs mx-auto pb-10 lg:pb-5 text-center border-b border-gray-100 lg:border-b-0">
                <div class="flex mx-auto mb-5 items-center justify-center w-14 h-14 rounded-full">
                  <img
                    src={Icon2}
                    alt="Best Construction company in Bangalore"
                  />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                  We work for 100% customer satisfaction
                </h4>
                <p class="text-balck font-semibold">
                  At Unipole Hoardings by Sri Raam Infra Ventures, our core
                  mission revolves around delivering unparalleled customer
                  satisfaction. We go above and beyond to understand our
                  clients.
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/3 mb-4 px-4 lg:pb-5">
              <div class="max-w-xs mx-auto text-center">
                <div class="flex mx-auto mb-5 items-center justify-center w-14 h-14 rounded-full ">
                  <img
                    src={Icon3}
                    alt="Best Construction company in Nagarbhavi"
                  />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                We invest in latest technology for Manufacturing
                </h4>
                <p class="text-black font-semibold">
                  {" "}
                  This technology empowers us to reproduce your designs with
                  unparalleled vibrancy, ensuring that your hoarding boards
                  captivate and engage your audience effectively.
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap mb-4 -mx-4">
            <div class="w-full lg:w-1/3 px-4 my-12 sm:mt-4 lg:mb-0 lg:border-r border-gray-100">
              <div class="max-w-xs mx-auto pb-2 sm:pb-10 lg:pb-5 text-center border-b border-gray-100 lg:border-b-0">
                <div class="flex mx-auto mb-5 items-center justify-center rounded-full w-14 h-14">
                  <img
                    src={Icon4}
                    alt=""
                  />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                  We have more than 10+ years of experience in manufacture
                </h4>
                <p class="text-black font-semibold">
                  With a proven track record, we bring over 10+ years of
                  expertise in the manufacturing hoarding industry to every project. Our seasoned
                  team's experience guarantees reliable, high-quality results.
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-8 sm:my-12 sm:mt-4 lg:mb-0 lg:border-r border-gray-100">
              <div class="max-w-xs mx-auto pb-2 sm:pb-10 lg:pb-5 text-center border-b border-gray-100 lg:border-b-0">
                <div class="flex mx-auto mb-5 items-center justify-center w-14 h-14 rounded-full">
                  <img
                    src={Icon5}
                    alt=""
                  />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                  Attention to Customer Satisfaction
                </h4>
                <p class="text-black font-semibold">
                  Our unwavering commitment to customer satisfaction is at the
                  core of everything we do. From the moment you engage with us,
                  we prioritize your needs, actively listen to your
                  requirements.
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/3 px-4 pb-2 sm:my-12 sm:mt-4 sm:pb-5 ">
              <div class="max-w-xs mx-auto text-center">
                <div class="flex mx-auto mb-5 items-center justify-center w-14 h-14 rounded-full ">
                  <img
                    src={Icon6}
                    alt=''
                  />
                </div>
                <h4 class="text-xl text-yellow-900 font-semibold mb-5">
                  Integrity and <br/>Professionalism
                </h4>
                <p class="text-black font-semibold">
                  Integrity and professionalism are the cornerstones of our
                  business. We conduct ourselves with the highest ethical
                  standards, always putting our clients' interests first.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
