import React from "react";
import { Link } from "react-router-dom";
import ImageOne from "../../images/shiv3.jpg";
import ImageTwo from "../../images/shiv4.jpg";
import ImageThree from "../../images/shiv12.jpg";
import ImageFour from "../../images/shiv5.jpg";
import ImageFive from "../../images/shiv8.jpg";
import ImageSix from "../../images/shiv9.jpg";

export default function section1() {
  return (
    <div>
      <section>
        <div
          class="md:py-20 sm:px-20  bg-white radius-for-skewed"
          style={{ fontFamily: "Figtree" }}
        >
          <div class="container md:px-4 mx-auto">
            <div class="md:mb-16 flex flex-wrap justify-center md:justify-between items-center">
              <div class="text-center lg:text-left">
                <h2 class="text-2xl lg:text-4xl font-heading text-yellow-900 mb-5 mt-2 font-semibold">
                  Featured Projects
                </h2>
              </div>

              <Link
                to="/projects"
                class="md:inline-block  md:py-2 px-5 md:px-6 rounded-l-xl rounded-t-xl bg-gradient-to-r from-yellow-600  to-yellow-600 text-gray-50 font-bold leading-loose transition duration-200"
                href="#"
              >
                View More Projects
              </Link>
            </div>
            <div class="flex flex-wrap mb-4">
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageOne} alt="" />
              </div>
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageTwo} alt="" />
              </div>
              <div class="p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageThree}
                  alt=""
                />
              </div>
              <div class=" p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageFour}
                  alt=""
                />
              </div>
              <div class="p-5">
                <img
                  class="block h-52 w-96 rounded-lg"
                  src={ImageFive}
                  alt=""
                />
              </div>
              <div class="p-5">
                <img class="block h-52 w-96 rounded-lg" src={ImageSix} alt="" />
              </div>
            </div>
   
          </div>
        </div>

        {/* -----------------------mobile-------------------- */}

        {/* <div class="md:py-20 px-4  bg-white radius-for-skewed" style={{fontFamily:'Figtree'}}>
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap justify-center md:justify-between items-center">
        <div class="text-center lg:text-left">
          <h2 class="text-4xl lg:text-5xl font-bold font-heading text-gray-800">Featured Projects</h2>
        </div>
        <a class="hidden md:inline-block py-2 px-6 rounded-l-xl rounded-t-xl bg-gradient-to-r from-orange-500  to-red-600 text-gray-50 font-bold leading-loose transition duration-200" href="#">View More Projects</a>
      </div>
      <div class="flex flex-wrap -mx-4 mb-4" >
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8  pl-1">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageOne} alt=""/>
        </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8 pl-1">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageTwo} alt=""/>
        </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8 pl-1">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageThree} alt=""/>
        </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8 pl-1 ">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageFour} alt=""/>
        </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8 pl-1">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageFive} alt=""/>
        </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 mb-8 pl-1">
        <div class="p-5">
        <img class="block h-52 w-96 rounded-lg" src={ImageSix} alt=""/>
        </div>
        </div>
      </div>
      <div class="text-center"><a class="md:hidden inline-block py-2 px-6 rounded-l-xl rounded-t-xl bg-gradient-to-r from-orange-500  to-red-600  text-white font-bold leading-loose transition duration-200" href="#">View More Projects</a></div>
    </div>
  </div> */}
      </section>
    </div>
  );
}
