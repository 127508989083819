import React from "react";
import Banner from "../../images/banners1.jpg";

export default function section2() {
  return (
    <div>
      <section>
        <img className="w-full h-48 md:h-96" src={Banner} alt="" />
      </section>

      <section
        class="md:pt-12 pt-6 pb-10 md:pb-20 overflow-hidden bg-yellow-100"
        style={{fontFamily:'Figtree'}}
      >
        <div class="container mx-auto">
          <p class="mb-5 font-heading font-semibold text-xs text-amber-600 text-center uppercase">
            Unipole Hoardings by Sri Raam Infra Ventures
          </p>
          <h2 class="md:mb-20 mb-10  font-heading text-2xl sm:text-4xl text-yellow-900 text-center">
            Why Choose Us
          </h2>
          <div class="flex flex-wrap -m-4">
            <div class="w-full md:w-1/2 p-4">
              <div class="md:max-w-lg mx-auto text-center">
                <svg
                  class="mx-auto mb-3"
                  width="50"
                  height="50"
                  viewbox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33301 12.5C8.33301 10.1988 10.1985 8.33334 12.4997 8.33334H16.6663C18.9675 8.33334 20.833 10.1988 20.833 12.5V16.6667C20.833 18.9679 18.9675 20.8333 16.6663 20.8333H12.4997C10.1985 20.8333 8.33301 18.9679 8.33301 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 12.5C29.1663 10.1988 31.0318 8.33334 33.333 8.33334H37.4997C39.8009 8.33334 41.6663 10.1988 41.6663 12.5V16.6667C41.6663 18.9679 39.8009 20.8333 37.4997 20.8333H33.333C31.0318 20.8333 29.1663 18.9679 29.1663 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8.33301 33.3333C8.33301 31.0322 10.1985 29.1667 12.4997 29.1667H16.6663C18.9675 29.1667 20.833 31.0322 20.833 33.3333V37.5C20.833 39.8012 18.9675 41.6667 16.6663 41.6667H12.4997C10.1985 41.6667 8.33301 39.8012 8.33301 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 33.3333C29.1663 31.0322 31.0318 29.1667 33.333 29.1667H37.4997C39.8009 29.1667 41.6663 31.0322 41.6663 33.3333V37.5C41.6663 39.8012 39.8009 41.6667 37.4997 41.6667H33.333C31.0318 41.6667 29.1663 39.8012 29.1663 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <p class="font-heading font-medium text-2xl md:text-3xl  text-gray-900 mb-10">
                  Manufacturing Excellence
                </p>
                <p class="w-full inline font-medium text-xl text-gray-900">
                  We are dedicated to delivering advertising structures of the
                  highest quality. Our manufacturing processes employ
                  state-of-the-art technology and craftsmanship to ensure
                  durability and visual appeal.
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-4">
              <div class="md:max-w-lg mx-auto text-center">
                <svg
                  class="mx-auto mb-3"
                  width="50"
                  height="50"
                  viewbox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33301 12.5C8.33301 10.1988 10.1985 8.33334 12.4997 8.33334H16.6663C18.9675 8.33334 20.833 10.1988 20.833 12.5V16.6667C20.833 18.9679 18.9675 20.8333 16.6663 20.8333H12.4997C10.1985 20.8333 8.33301 18.9679 8.33301 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 12.5C29.1663 10.1988 31.0318 8.33334 33.333 8.33334H37.4997C39.8009 8.33334 41.6663 10.1988 41.6663 12.5V16.6667C41.6663 18.9679 39.8009 20.8333 37.4997 20.8333H33.333C31.0318 20.8333 29.1663 18.9679 29.1663 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8.33301 33.3333C8.33301 31.0322 10.1985 29.1667 12.4997 29.1667H16.6663C18.9675 29.1667 20.833 31.0322 20.833 33.3333V37.5C20.833 39.8012 18.9675 41.6667 16.6663 41.6667H12.4997C10.1985 41.6667 8.33301 39.8012 8.33301 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 33.3333C29.1663 31.0322 31.0318 29.1667 33.333 29.1667H37.4997C39.8009 29.1667 41.6663 31.0322 41.6663 33.3333V37.5C41.6663 39.8012 39.8009 41.6667 37.4997 41.6667H33.333C31.0318 41.6667 29.1663 39.8012 29.1663 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <p class="font-heading font-medium text-2xl md:text-3xl  text-gray-900 mb-10">
                  {" "}
                  Customization
                </p>
                <p class="font-heading font-medium text-xl text-gray-900">
                  {" "}
                  We understand that each advertising campaign is unique. Our
                  team works closely with you to design and fabricate structures
                  that align with your brand's identity and campaign objectives.
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -m-4 mt-10">
            <div class="w-full md:w-1/2 p-4">
              <div class="md:max-w-lg mx-auto text-center">
                <svg
                  class="mx-auto mb-3"
                  width="50"
                  height="50"
                  viewbox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33301 12.5C8.33301 10.1988 10.1985 8.33334 12.4997 8.33334H16.6663C18.9675 8.33334 20.833 10.1988 20.833 12.5V16.6667C20.833 18.9679 18.9675 20.8333 16.6663 20.8333H12.4997C10.1985 20.8333 8.33301 18.9679 8.33301 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 12.5C29.1663 10.1988 31.0318 8.33334 33.333 8.33334H37.4997C39.8009 8.33334 41.6663 10.1988 41.6663 12.5V16.6667C41.6663 18.9679 39.8009 20.8333 37.4997 20.8333H33.333C31.0318 20.8333 29.1663 18.9679 29.1663 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8.33301 33.3333C8.33301 31.0322 10.1985 29.1667 12.4997 29.1667H16.6663C18.9675 29.1667 20.833 31.0322 20.833 33.3333V37.5C20.833 39.8012 18.9675 41.6667 16.6663 41.6667H12.4997C10.1985 41.6667 8.33301 39.8012 8.33301 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 33.3333C29.1663 31.0322 31.0318 29.1667 33.333 29.1667H37.4997C39.8009 29.1667 41.6663 31.0322 41.6663 33.3333V37.5C41.6663 39.8012 39.8009 41.6667 37.4997 41.6667H33.333C31.0318 41.6667 29.1663 39.8012 29.1663 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <p class="font-heading font-medium text-2xl md:text-3xl   text-gray-900 mb-10">
                  On-time Installation
                </p>
                <p class="font-heading font-medium text-xl text-gray-900">
                  Timeliness is crucial in the advertising industry. We are
                  committed to delivering and setting up your advertising
                  structures on schedule, ensuring your campaign runs smoothly.
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-4">
              <div class="md:max-w-lg mx-auto text-center">
                <svg
                  class="mx-auto mb-3"
                  width="50"
                  height="50"
                  viewbox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33301 12.5C8.33301 10.1988 10.1985 8.33334 12.4997 8.33334H16.6663C18.9675 8.33334 20.833 10.1988 20.833 12.5V16.6667C20.833 18.9679 18.9675 20.8333 16.6663 20.8333H12.4997C10.1985 20.8333 8.33301 18.9679 8.33301 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 12.5C29.1663 10.1988 31.0318 8.33334 33.333 8.33334H37.4997C39.8009 8.33334 41.6663 10.1988 41.6663 12.5V16.6667C41.6663 18.9679 39.8009 20.8333 37.4997 20.8333H33.333C31.0318 20.8333 29.1663 18.9679 29.1663 16.6667V12.5Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8.33301 33.3333C8.33301 31.0322 10.1985 29.1667 12.4997 29.1667H16.6663C18.9675 29.1667 20.833 31.0322 20.833 33.3333V37.5C20.833 39.8012 18.9675 41.6667 16.6663 41.6667H12.4997C10.1985 41.6667 8.33301 39.8012 8.33301 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29.1663 33.3333C29.1663 31.0322 31.0318 29.1667 33.333 29.1667H37.4997C39.8009 29.1667 41.6663 31.0322 41.6663 33.3333V37.5C41.6663 39.8012 39.8009 41.6667 37.4997 41.6667H33.333C31.0318 41.6667 29.1663 39.8012 29.1663 37.5V33.3333Z"
                    stroke="#D11F74"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <p class="font-heading font-medium text-2xl md:text-3xl   text-gray-900 mb-10">
                  Comprehensive Range
                </p>
                <p class="font-heading font-medium text-xl text-gray-900">
                  Explore our diverse product range, including Unipole
                  Hoardings, Digital Billboard Hoardings, 3D Billboard
                  Hoardings, and Highway Billboard Hoardings. We have the
                  expertise to manufacture and install structures for various
                  advertising applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
