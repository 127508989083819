import React from "react";
import Banner from "../../images/banners.jpg";
import Road1 from "../../images/blank-large-hoarding-billboard-against-sky_23-2147901086.jpg";
import Road from "../../images/white-blank-billboard-illuminated-highway-during-night_23-2147901037.jpg";


import Uni from "../../images/hoarding.webp";
import Uni1 from "../../images/3dhording.jpg";
import Uni3 from "../../images/WhatsApp3.jpg";
import Uni4 from "../../images/highway.jpg";
import Uni5 from "../../images/Road direction signs on poles set.jpg";
import Place from "../../images/shiv/traffic-signs-road (1).jpg";
import Place1 from "../../images/Road direction signs on poles set.jpg";
import High1 from "../../images/high1.jpg";
import High2 from "../../images/highway-billboard-hoardings-upscaled.jpg";
import Digi from "../../images/3d-billboard-hoarding-upscaled.png";
import Digi1 from "../../images/images.jpeg";
import Multi from "../../images/advertising-panel-residential-area_23-2147714944.jpg";
import Multi1 from "../../images/one.png";







export default function section1() {
  return (
    <div>
      <section>
        <img className="w-full h-48 md:h-[500px]" src={Banner} />
      </section>

      <section
        class="md:pt-24 md:pb-36 bg-white overflow-hidden mx-10"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="container md:px-4 md:mx-auto">
          <h2
            class="mb-4 text-4xl md:text-4xl text-center text-yellow-900 font-heading font-heading font-semibold"
            style={{ fontFamily: "Figtree" }}
          >
            Our Detailed Services
          </h2>
          <p
            class=" md:mb-24 font-medium text-gray-900 text-center leading-relaxed md:max-w-lg mx-auto mb-10"
            style={{ fontFamily: "Figtree" }}
          >
            Make a Statement with Unipole Hoardings
          </p>
          <div class="flex flex-wrap md:-m-8 md:mx-20">
            <div class="w-full md:w-1/2 md:p-8 p-4">
              <div class="flex flex-wrap lg:items-center -m-4">
                <div class="w-auto md:p-4">
                  <div class="overflow-hidden rounded-xl md:w-full">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000 h-48 md:h-[300px]"
                      src={Road}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:max-w-lg">
              <div class="flex flex-col justify-between h-full md:mt-10">
                <div class="mb-6">
                  <p class="mb-1.5 md:text-3xl text-xl text-gray-900 font-medium uppercase">
                    Unipole Hoarding
                  </p>
                  <div class="md:w-auto">
                    <p class="md:text-lg text-yellow-600 font-semibold md:mb-5">
                      Make a Statement with Unipole Hoardings
                    </p>
                  </div>
                  <p class="inline text-justify" href="#">
                    <h3 class="text-base font-semibold">
                      Our Unipole Hoardings are iconic structures that symbolize
                      the pinnacle of outdoor advertising. These towering
                      structures are meticulously designed and manufactured to
                      not only capture attention but also to create a lasting
                      impression. Unipole hoardings are more than just
                      advertising tools; they are visual landmarks that stand
                      tall in the landscape, making them an excellent choice for
                      showcasing your campaigns.
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:mt-10 md:ml-28 md:w-[590px]">
              <p className="md:text-xl text-lg font-semibold mb-5 text-yellow-600">
                With Unipole Hoardings by Sri Raam Infra Ventures, you can
                expect :
              </p>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Strategic Visibility :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;Our unipoles are strategically located to ensure maximum
                  exposure to your target audience. They rise above the clutter,
                  ensuring that your message remains visible and impactful.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Sturdiness and Durability:
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;Crafted with precision and using high-quality materials,
                  our unipoles are built to withstand the elements. They provide
                  a reliable platform for your advertising, even in challenging
                  weather conditions.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Customization:
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;We understand that every campaign has its unique
                  requirements. That's why we offer customization options that
                  allow you to tailor the design, size, and location of your
                  unipole hoarding to match your branding and campaign
                  objectives.
                </p>
              </li>
            </div>
            <div>
              <div className="md:flex mt-10 gap-10 md:mt-10 hidden md:block">
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40"
                  src={Road1}
                  alt=""
                />
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40"
                  src={Uni}
                  alt=""
                />
              </div>
            </div>
          </div>
          <hr class="h-px my-8 mx-28 bg-yellow-900 border-0"></hr>

          {/* ............................................................................................................................................. */}

          <div class="flex flex-wrap md:-m-8 md:mx-20 md:pt-10">
            <div class="w-full md:w-1/2 md:p-8 p-4">
              <div class="flex flex-wrap lg:items-center -m-4">
                <div class="w-auto md:p-4">
                  <div class="overflow-hidden rounded-xl md:w-full">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000 h-48 md:h-[300px]"
                      src={Uni3}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:max-w-lg">
              <div class="flex flex-col justify-between h-full md:mt-10">
                <div class="mb-6">
                  <p class="mb-1.5 md:text-3xl text-xl text-gray-900 font-medium uppercase tracking-px">
                    Digital Billboard Hoardings
                  </p>
                  <div class="md:w-auto">
                    <p class="md:text-lg text-yellow-600 font-semibold leading-relaxed md:mb-5">
                      Digital Billboard Hoardings are at the forefront of
                      innovation.
                    </p>
                  </div>
                  <p class="inline text-justify" href="#">
                    <h3 class="text-base font-semibold">
                      In the age of digital marketing, our Digital Billboard
                      Hoardings are at the forefront of innovation. These
                      cutting-edge hoardings embrace technology to deliver
                      dynamic and interactive advertising experiences. With
                      vibrant LED displays and real-time content updates, they
                      are perfect for captivating your audience and ensuring
                      your message remains fresh and engaging.
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:mt-10 md:ml-28 md:w-[590px]">
              <p className="md:text-xl text-lg font-semibold mb-5 text-yellow-600">
                Choose our Digital Billboard Hoardings for :
              </p>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Dynamic Content :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp; Keep your audience engaged with dynamic content that
                  can be updated in real time. Promote events, offers, or
                  products with ease.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  High Impact :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;The vivid colors and high-resolution displays of our
                  digital billboards ensure that your message stands out and
                  leaves a memorable impression.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Cost-Efficiency :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;Despite their advanced technology, our digital
                  billboards are energy-efficient and cost-effective, making
                  them a sustainable advertising choice.
                </p>
              </li>
            </div>
            <div>
              <div className="md:flex mt-10 gap-10 hidden md:block">
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={Multi}
                  alt=""
                />
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={Multi1}
                  alt=""
                />
              </div>
            </div>
          </div>
          <hr class="h-px my-8 mx-28 bg-yellow-900 border-0"></hr>

          {/* ............................................................................................................................. */}

          <div class="flex flex-wrap md:-m-8 md:mx-20 md:pt-10">
            <div class="w-full md:w-1/2 md:p-8 p-4">
              <div class="flex flex-wrap lg:items-center -m-4">
                <div class="w-auto md:p-4">
                  <div class="overflow-hidden rounded-xl md:w-full">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000 h-48 md:h-[300px]"
                      src={Uni1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:max-w-lg">
              <div class="flex flex-col justify-between h-full md:mt-10">
                <div class="mb-6">
                  <p class="mb-1.5 md:text-3xl text-xl text-gray-900 font-medium uppercase tracking-px">
                    3D Billboard Hoardings
                  </p>
                  <div class="md:w-auto">
                    <p class="md:text-lg text-yellow-600 font-semibold leading-relaxed md:mb-5">
                      Elevate your brand's visibility to new heights with our
                      striking 3D Billboard Hoardings.
                    </p>
                  </div>
                  <p class="inline text-justify" href="#">
                    <h3 class="text-base font-semibold">
                      Elevate your brand's visibility to new heights with our
                      striking 3D Billboard Hoardings. These unique structures
                      create a three-dimensional visual experience that
                      captivates and engages your audience. Whether you want to
                      convey depth, dimension, or a sense of grandeur, our 3D
                      billboard hoardings are designed to stand out and leave a
                      lasting impression.
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:mt-10 md:ml-28 md:w-[590px]">
              <p className="md:text-xl text-lg font-semibold mb-5 text-yellow-600">
                Choose our 3D Billboard Hoardings for :
              </p>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Attention-Grabbing Designs :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp; Our creative team can craft intricate and eye-catching
                  3D designs that set your brand apart from the competition.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Immersive Experiences :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;3D billboard hoardings provide an immersive experience
                  for viewers, making your message more memorable and impactful.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Versatility :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;From artistic displays to product showcases, our 3D
                  billboard hoardings can be customized to suit a wide range of
                  advertising needs.
                </p>
              </li>
            </div>
            <div>
              <div className="md:flex mt-10 gap-10 md:mt-10 hidden md:block">
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={Digi}
                  alt=""
                />
                <img 
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={Digi1}
                  alt=""
                />
              </div>
            </div>
          </div>
          <hr class="h-px my-8 mx-28 bg-yellow-900 border-0"></hr>

          {/* ...................................................................................................................... */}

          <div class="flex flex-wrap md:-m-8 md:mx-20 md:pt-10">
            <div class="w-full md:w-1/2 md:p-8 p-4">
              <div class="flex flex-wrap lg:items-center -m-4">
                <div class="w-auto md:p-4">
                  <div class="overflow-hidden rounded-xl md:w-full">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000 h-48 md:h-[300px]"
                      src={Uni4}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:max-w-lg">
              <div class="flex flex-col justify-between h-full mt-10">
                <div class="mb-6">
                  <p class="mb-1.5 md:text-3xl text-xl text-gray-900 font-medium uppercase tracking-px">
                    Highway Billboard Hoardings
                  </p>
                  <div class="md:w-auto">
                    <p class="md:text-lg text-yellow-600 font-semibold leading-relaxed md:mb-5">
                      To reach a broader and diverse audience, consider our
                      strategically located Highway Billboard Hoardings.
                    </p>
                  </div>
                  <p class="inline text-justify" href="#">
                    <h3 class="text-base font-semibold">
                      To reach a broader and diverse audience, consider our
                      strategically located Highway Billboard Hoardings. These
                      hoardings are strategically positioned along major
                      highways across India, ensuring maximum exposure for your
                      campaigns. They provide an effective way to connect with
                      commuters and travelers, making them an invaluable
                      addition to your advertising strategy.
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:mt-10 md:ml-28 md:w-[590px]">
              <p className="md:text-xl text-lg font-semibold mb-5 text-yellow-600">
                Choose our Highway Billboard Hoardings for :
              </p>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Wide Reach :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp; Our highway billboard hoardings offer unmatched
                  visibility, making them ideal for campaigns that need to reach
                  a vast and diverse audience.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Roadside Impact :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;Whether it's long-distance travelers or local commuters,
                  your message will be seen by a continuous stream of potential
                  customers.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Brand Consistency :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp; We maintain high standards in the design and placement
                  of our highway billboards to ensure your brand is presented
                  consistently and professionally.
                </p>
              </li>
            </div>
            <div>
              <div className="md:flex mt-10 gap-10 md:mt-10 hidden md:block">
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={High1}
                  alt=""
                />
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40 w-60"
                  src={High2}
                  alt=""
                />
              </div>
            </div>
          </div>

          <hr class="h-px my-8 mx-28 bg-yellow-900 border-0"></hr>

          {/* ....................................................................................................................... */}

          <div class="flex flex-wrap md:-m-8 md:mx-20 pt-10">
            <div class="w-full md:w-1/2 md:p-8 p-4">
              <div class="flex flex-wrap lg:items-center -m-4">
                <div class="w-auto md:p-4">
                  <div class="overflow-hidden rounded-xl md:w-full">
                    <img
                      class="transform hover:scale-105 transition ease-in-out duration-1000 h-48 md:h-[300px]"
                      src={Uni5}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="md:max-w-lg">
              <div class="flex flex-col justify-between h-full mt-10">
                <div class="mb-6">
                  <p class="mb-1.5 md:text-3xl text-xl text-gray-900 font-medium uppercase tracking-px">
                    Road Sign Board
                  </p>
                  <div class="md:w-auto">
                    <p class="md:text-lg text-yellow-600 font-semibold leading-relaxed md:mb-5">
                    At Road Sign Boards by Sri Raam Infra Ventures, we are committed to enhancing road safety and ensuring effective communication on the streets and highways.
                    </p>
                  </div>
                  <p class="inline text-justify" href="#">
                    <h3 class="text-base font-semibold">
                      Our Road Sign Boards are the silent heroes of road safety
                      and navigation. These meticulously designed and crafted
                      signboards play a crucial role in guiding, informing, and
                      ensuring safety on the streets and highways. Much like our
                      iconic Unipole Hoardings, our road sign boards are visual
                      aids that are strategically placed to make a significant
                      impact on road communication.
                    </h3>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:mt-10 md:ml-28 md:w-[590px]">
              <p className="md:text-xl text-lg font-semibold mb-5 text-yellow-600">
                When you choose Road Sign Boards by Sri Raam Infra Ventures, you
                can expect :
              </p>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Clarity and Guidance:
                </span>
                <p className="font-semibold md:inline">
                  &nbsp; Our road sign boards are strategically positioned to
                  provide clear and concise information. They guide drivers,
                  inform them of potential hazards, and direct them effectively
                  to their destinations.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                  Durability and Reliability:
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;Crafted with precision and using high-quality materials,
                  our road sign boards are built to last. They can withstand the
                  elements, ensuring that vital information remains visible and
                  effective, no matter the weather.
                </p>
              </li>
              <li className="md:mt-3 mb-5 md:mb-0">
                <span className="text-base md:text-lg font-semibold">
                Customization :
                </span>
                <p className="font-semibold md:inline">
                  &nbsp;  We recognize that each road situation is unique. Whether it's regulatory signs, warning signs, or informational signs, our team can create custom sign boards to match your specific needs. From design to size, and even language, we tailor our solutions to your requirements.
                </p>
              </li>
            </div>
            <div>
              <div className="md:flex mt-10 gap-10 ml-10 hidden md:block">
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40"
                  src={Place}
                  alt=""
                />
                <img
                  class="transform hover:scale-105 transition ease-in-out duration-1000 h-40"
                  src={Place1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
