import React from "react";

export default function section1() {
  return (
    <div>
      <section
        class="py-16 md:py-10 sm:px-24 overflow-hidden bg-white"
        style={{ fontFamily: "Figtree" }}
      >
        <div class="container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
                <div class="max-w-xl mx-auto lg:mx-0">
                  <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-white bg-yellow-600 rounded-full">
                    CONTACT US
                  </span>
                  <h1 class="max-w-md font-heading text-3xl xs:text-6xl font-bold text-yellow-900 mb-4">
                    <span> Get in Touch</span>
                  </h1>
                  <p class="text-xl text-gray-900 font-semibold mb-16">
                    Ready to elevate your brand's visibility? Contact Unipole
                    Hoardings by Sri Raam Infra Ventures today. Let's discuss
                    how we can help you create a lasting impact in the dynamic
                    landscape of outdoor advertising.
                  </p>
                  <div class="flex mb-14 items-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-yellow-600">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <span class="sm:text-md text-gray-500">Email</span>
                      <span class="block text-lg sm:text-xl font-semibold text-gray-900">
                        Sriraaminfraventures.gmail.com
                      </span>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-yellow-600">
                      <svg
                        fill="white"
                        width="30px"
                        height="30px"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z" />
                      </svg>
                    </div>
                    <div>
                      <span class="sm:text-md text-gray-500">Phone</span>
                      <span class="block text-lg sm:text-xl font-semibold text-gray-900">
                        +91-9900548383
                      </span>
                    </div>
                  </div>
                  <div class="flex mt-14 items-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-10 sm:w-12 h-10 sm:h-12 rounded-full bg-yellow-600">
                      <svg
                        fill="white"
                        width="40px"
                        height="40px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                      </svg>
                    </div>
                    <div>
                      <span class="sm:text-md text-gray-500">Address</span>
                      <span class="block text-lg sm:text-xl font-semibold text-gray-900">
                        {" "}
                        Nagarabhavi Bangalore 560091 Karnataka{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 px-4">
                <div class="h-full rounded-4xl overflow-hidden max-w-xl mx-auto lg:mr-0">
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.838301886035!2d77.4860095!3d12.9424176!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f5658a9175f%3A0x763b9efe53b95126!2sVasuki%20Ventures!5e0!3m2!1sen!2sin!4v1685172940044!5m2!1sen!2sin" width="450" height="550"  style={{borderRadius:'20px'}}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248853.4594432036!2d77.32727964350116!3d12.950383175680345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d2a2729eebb%3A0x374abc12c98f3f4e!2sSri%20Raam%20Infra%20Ventures!5e0!3m2!1sen!2sin!4v1696844636701!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
