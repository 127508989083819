import React from "react";
import Slider from "react-slick";
import ImageOne from "../../images/shiv1.jpg";
import ImageTwo from "../../images/shiv2.jpg";
import ImageThree from "../../images/shiv3.jpg";
import ImageFour from "../../images/shiv8.jpg";
import ImageFive from "../../images/shiv5.jpg";
import ImageSix from "../../images/shiv6.jpg";
import ImageSeven from "../../images/shiv7.jpg";
import ImageEight from "../../images/shiv4.jpg";

export default function section5() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    responsive: [
      {
      
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
       
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
       
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      class="relative py-10 lg:py-20 overflow-hidden bg-white hidden lg:block"
      style={{ fontFamily: "Figtree" }}
    >
      <div class="relative container px-4 mx-auto">
        <div class="text-center mb-18">
          <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-white bg-yellow-600 rounded-full">
            Work Speaks
          </span>
          <h1 class="font-heading text-3xl md:text-4xl text-yellow-900 mb-10 font-semibold">
            <span>Our Recent Works</span>
          </h1>
        </div>
      </div>

      <div>
        <Slider {...settings}>
          <div>
            <div class="w-full px-4">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#">
                <div class="relative md:w-full md:h-72 mb-3 ">
                  <img
                    class="block md:w-full md:h-full rounded-md"
                    src={ImageOne}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class="w-full  px-4 ">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block md:w-full md:h-full  rounded-md"
                    src={ImageTwo}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class=" w-full  px-4 rounded-sm">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block md:w-full md:h-full  rounded-md"
                    src={ImageThree}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class=" w-full  px-4 rounded-sm">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block w-full h-full  rounded-md"
                    src={ImageFour}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class="w-full  px-4 rounded-sm">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block w-full h-full  rounded-md"
                    src={ImageFive}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class=" w-full  px-4">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block w-full h-full  rounded-md"
                    src={ImageSix}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class=" w-full  px-4">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block w-full h-full  rounded-md"
                    src={ImageSeven}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
          <div>
            <div class=" w-full  px-4">
              <a
                class="group block max-w-sm mx-auto md:max-w-none h-full"
                href="#"
              >
                <div class="relative md:w-full md:h-72 mb-3">
                  <img
                    class="block w-full h-full  rounded-md"
                    src={ImageEight}
                    alt=""
                  />
                </div>
              </a>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}
