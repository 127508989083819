import React from 'react'
import SectionOne from './sectionone'
import Appwrap from '../global/appwrap'

export default function index() {
  return (
    <div>
         {/* <Appwrap/> */}
        <SectionOne/>
       
    </div>
  )
}
